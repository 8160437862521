<template>
    <div :class="{'loading-panel': loading}" v-loading="loading" style="display: block">
        <div class="word-max clickable" @click="maxFrame">最大化Word</div>
        <div class="word-min clickable" @click="minFrame">复原Word</div>
        <div id="corp-word"></div>
        <div style="opacity: 0;" v-if="loading">
            <div id="canvas-agent1" class="my-canvas"></div>
            <div id="canvas-agent2" class="my-canvas"></div>
            <div id="canvas-agent3" class="my-canvas"></div>
            <div id="canvas-agent5" class="my-canvas"></div>
            <div id="canvas-agent6" class="my-canvas"></div>
        </div>
    </div>
</template>

<script>
    import canvasHelp from './help'
    export default {
        props: {
            comCode: {
                type: String,
                default: ''
            },
            compCode: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                loading: true,
                max: false,
                docParam: {},
            }
        },
        mounted() {
            this.$api.get('report/agentSysUserReport/queryBaseParamByComCode', {comCode: this.comCode, compCode: this.compCode})
                .then(res => {
                    const list = []
                    let index = 2
                    while (true) {
                        const val = res.data.metaInfo.year[index.toString()]
                        if (val) {
                            list.push(parseInt(val))
                            index++
                        } else {
                            break
                        }
                    }
                    canvasHelp.renderLine('canvas-agent1', res.data.saleInfo.productSaleInfoMap.saleTend || [])
                    canvasHelp.renderMulLine('canvas-agent2', canvasHelp.getFrontData(res.data.saleInfo.productSaleInfoMap.saleTendGroupDose), list, 'comName')
                    canvasHelp.renderMulLine('canvas-agent3', canvasHelp.getFrontData(res.data.saleInfo.productSaleInfoMap.comSaleTendGroupComp), list, 'compName')
                    canvasHelp.renderPie('canvas-agent5', res.data.saleInfo.productSaleInfoMap.saleAmountRatio || [])
                    canvasHelp.renderPie('canvas-agent6', res.data.saleInfo.productSaleInfoMap.compSaleAmountRatio || [], 'compName')

                    setTimeout(() => {
                            this.$api.post('report/sysUserReport/createWord', {
                                compCode: this.compCode,
                                comCode: this.comCode,
                                reportType: 'AGENT_TYPE',
                                param: {
                                    comSaleTendPic: this.getBase64ByEcharts('canvas-agent1'),
                                    saleTendGroupDosePic: this.getBase64ByEcharts('canvas-agent2'),
                                    comSaleTendGroupCompPic: this.getBase64ByEcharts('canvas-agent3'),
                                    saleAmountRatioPic: this.getBase64ByEcharts('canvas-agent5'),
                                    compSaleAmountRatioPic: this.getBase64ByEcharts('canvas-agent6'),
                                }
                            })
                                .then(res => {
                                    this.docParam = res.data || {}
                                    setTimeout(() => {
                                        this.loadWord()
                                    }, 10)
                                })
                                .finally(() => {
                                    this.loading = false
                                })
                        }, 1000)
                })


        },
        methods: {
            getBase64ByEcharts(id) {
                return window.echarts.init(document.getElementById(id)).getDataURL().replace('data:image/png;base64,', '')
            },
            loadWord() {
                const fullHeight = document.querySelector('.taoshu-body').clientHeight
                const tabHeight = document.querySelector('.general-template__tab').clientHeight
                const conditionHeight = document.querySelector('.general-template__condition').clientHeight
                new window.DocsAPI.DocEditor("corp-word", {
                    "width": "100%",
                    "height": `${fullHeight - tabHeight - conditionHeight - 75}px`,
                    "type": "desktop",
                    "token": "",
                    "document": {
                        "title": this.docParam.title,
                        "url":  this.docParam.url,
                        "fileType": "docx",
                        "key": this.docParam.key,
                        "info": {
                            "owner": "Me",
                            "favorite": null
                        },
                        "permissions": {
                            "comment": false,
                            "copy": true,
                            "download": true,
                            "edit": true,
                            "print": false,
                            "fillForms": true,
                            "modifyFilter": true,
                            "modifyContentControl": true,
                            "review": true,
                            "reviewGroups": null,
                            "commentGroups": {},
                            "userInfoGroups": null
                        }
                    },
                    "editorConfig": {
                        "actionLink": null,
                        "mode": "edit",
                        spellcheck: false,
                        "lang": "zh",
                        "callbackUrl": `${window.location.origin}/taoshu-data/saveReport?reportType=AGENT_TYPE&comCode=${this.comCode}&compCode=${this.compCode}&token=${this.$help.token.get()}`,
                        "user": {
                            "id": this.$store.state.user.id,
                            "name": this.$store.state.user.userName
                        },

                        "customization": {
                            "about": false,
                            "comments": false,
                            "feedback": false,
                            "forcesave": true,
                            // "goback": {
                            //     "url": "http://localhost/example/"
                            // },
                            "submitForm": false
                        },
                        "fileChoiceUrl": "",
                        "plugins": {
                            "pluginsData":[
                                '/officePlugin/test/config.json'
                            ]
                        }
                    }
                });
            },
            maxFrame() {
                this.max = true
                document.querySelector('iframe').className = 'max-frame'
            },
            minFrame() {
                this.max = false
                document.querySelector('iframe').className = ''
            },
        },
    }
</script>